/* eslint-disable */
import React, { Component } from 'react';
import Cookies from 'js-cookie';

class Home extends Component {
    state = {};

    loadPage = () => {
        const ModuleName = 'Edogov_Reporting';
        const homelink = Cookies.get(ModuleName + '_HomeLink');

        window.location.assign(homelink);
        // Cookies.remove("token");
        // Cookies.remove("tokenExist");
    };

    componentDidMount() {
        // Cookies.remove("token");
        this.loadPage();
    }

    render() {
        return <></>;
    }
}

export default Home;
