import Cookies from 'js-cookie'

export const logout = () => {
    const ModuleName = 'Edogov_Reporting';

    Cookies.set('token', null);
    Cookies.set('tokenExist', false);
    Cookies.set('fullname', null);
    Cookies.set('role', null);
    window.location.href = `${Cookies.get(ModuleName + '_HomeLink')}`;
}


export default logout