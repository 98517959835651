import * as React from "react";
import ColorController from "../layouts/ColorController";
import GrievanceReviewDetail from '../pages/GrievanceReviewDetails'
import moment from 'moment';

const TransactionCard = (props) => {
  // console.log(moment(props.memoDate))

  const [showGrievanceDetail, setShowGrievanceDetail] = React.useState(
    false
  );


  return (
    <div>
      <div
        className="flex m-t-5 table-row"
        style={{
          boxShadow: "0px 2px 6px #0000000A",
          padding: props.padding || ".6rem",
          fontSize: props.fontSize || "13px",
          cursor: "pointer",
          backgroundColor:
            "#ffffff",
          bgColor: "#fff",
          height: "70px",
          transition: "all 0.6s ease-in",
        }}

        onClick={() => {
          setShowGrievanceDetail(showGrievanceDetail ? false : true);
        }}
      >
        {console.log("logging comment", props.comment)}
        <div
          style={{ width: "45%", fontSize: "13px", margin:"20px 8px 20px 0px" }}
          className=" my-auto td"
        >

          {props.subject ||
            props.title}
        </div>
        <div
          style={{width: "35%", fontSize: "13px", margin:"20px 8px 20px 0px" }}
          className=" my-auto td"
        >

          { props.from || props.initiator
            }
        </div>

        <div style={{width: "29%", fontSize: "13px" }}>
        <div
          className="flex space-around text-center  my-auto td"
          
        >
          <div className="m-r-auto flex td">
            <div
              
              style={{
                margin:"20px 8px 20px 0px",
                width:"15px",
                backgroundColor: `${props.status ? ColorController.getColor(props.status) : "red"}`,
                borderRadius: "50%",
                height: "1.1rem",
              }}
            ></div>
            <div className="my-auto td" style={{margin:"20px 20px 20px 0px"}}>
              {props.status === "Approved" ? "Acknowledged" : props.status || ""}
            </div>
          </div>
        </div>
        </div>
        <div
          className=""
          style={{
            borderLeft: "1px solid gray",
            width: "27%",
            
          }}
        >
          <div style={{margin:"20px 8px 20px 0px"}}>
          <b className="bold-text td p-l-20" >Last Treated:</b>
          {props.memoDate?.replace('12:00 AM','') || props.initiatedAt?.replace('12:00 AM','')}
          </div>
          
        </div>
      </div>

      {showGrievanceDetail && (
        <div>
          <GrievanceReviewDetail
            // height={height}
            key={props.id}
            id={props.id}
            to={props.to||props.role}
            memoDate={props.memoDate}
            from={props.from||props.initiator}
            through={props.through}
            content={props.content}
            isTreated={props.isTreated}

            //showGrievanceDetail={props.showGrievanceDetail}
            //filterReview={props.filterReviewsOnApprover}
            comments={props.comments}
            documents={props.documents}
            style={{ transition: "all 0.6s ease-in" }}
          />
        </div>
      )}

    </div>

  );
};

export default TransactionCard;
