import React, { Component } from 'react'
import Cookies from 'js-cookie'
import LoadingSpinnerEdo from "../../resources/loadingSpinner";


class Logout extends Component {
    state = {
        loaing: true
    }

    loadHomePage = () => {
        const ModuleName = 'Edogov_Reporting';
        window.location.href = `${Cookies.get(ModuleName + '_HomeLink')}`;
        Cookies.remove("token");
        Cookies.remove("tokenExist");
    }

    componentDidMount() {
        Cookies.remove("token");
        this.loadHomePage();
    }


    render() {
        const { loading } = this.state
        return (<>
            <div
                className="w-100 flex flex-v-center flex-h-center"
                style={{ minHeight: "105vh" }}
            >
                {loading ? <LoadingSpinnerEdo /> : ''}
            </div>
        </>);
    }
}

export default Logout;