import axios from "axios";
//import { Post } from '../../utility/fetch';
//import { URLAPI } from '../../utility/ApiMethods';
import React, { Component } from 'react'
import LocationIcon from '../../assets/images/location-icon-100.png';
import moment from 'moment'
import Cookies from "js-cookie";
//import { GiThirdEye } from "react-icons/gi";



class LocationDetails extends Component {
  constructor(props) {

    super(props);

    this.state = {

      userRole: "",
      latitude: null,
      longitude: null,
      userAddress: null,
      // time: new moment()

    }
    this.getLocation = this.getLocation.bind(this);
    this.getCoordinates = this.getCoordinates.bind(this);
    this.reverseGeocodeCoordinates = this.reverseGeocodeCoordinates.bind(this);
  }

  getLocation = e => {

    console.log('clicked');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
    } else {
      // alert("Geolocation is not supported by this browser.");

      console.log("Geolocation is not supported by this browser.");
    }
  }

  getCoordinates = (position) => {
    console.log(position.coords.latitude);
    console.log(position.coords.longitude);
    let data = {
      lattitude: "" + position.coords.latitude + "",
      longitude: "" + position.coords.longitude + ""
    }


    this.reverseGeocodeCoordinates(data);
  }



  reverseGeocodeCoordinates = (data) => {
    console.log(data);
    axios.post("https://edogoverp.com/services/api/userlocations", data, {
      headers: {
        "Access-Control-Allow-Credentials": true,
        crossorigin: true,
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response.data);
        Cookies.set("latitude", response.data.location)

        this.setState({
          latitude: response.data.location

        })
      }, (error) => {
        console.log(error);
      });

  }



  handleLocationError = (error) => {

    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.")
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.")
        break;
      default:
        console.log("An unknown error occured.")
    }

  }

  componentDidMount() {
    // this.getLocation();
    Cookies.set("loginTime", moment().format("HH:mm a"));

  }



  render() {
    return (
      <>
        <div className="location-details flex flex-v-center">
          <div className=" m-r-5 right-text" style={{ paddingTop: '0px' }}>
            {/* <p className="location-details location" >{Cookies.get("latitude")}</p> */}
            <p className="location-details login-time" >Last Logged in: {Cookies.get("loginTime")}</p>

          </div>
          <div className="flex">
            <button type="button" style={{ background: "none" }} onClick={this.getLocation}>
              <img className="location-img m-r-5 " src={LocationIcon} alt="user avater" />
            </button>
          </div>
        </div>

      </>
    );
  }
}

export default LocationDetails;