import React, { Component } from 'react'
import { IoMdClose } from 'react-icons/io';
import Loader from '../../assets/svg/loading.svg';
import { URLAPI } from '../../utility/ApiMethods'
import { Post, Get, Put } from '../../utility/fetch'
import TextInput from '../inputs/TextInput';
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';
import { errorMessage, successMessage } from '../../resources/alert';



class ApprovalSetupForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            roleid: 0,
            sequences: 0,
            formErrors: {},
            errorMessage: "",
            button2: 'Add-Role',
            className: '',
            class: "btn btn-large w-100",
            class2: "close-btn pointer",
            loading: false,
            close: false,
            records: [],
            visible: false,
            roles: [],
            date: "09-Dec-2020 02:41:39 PM"

        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });

    }


    getSavedRole = (data) => {
        console.log(data);
        if (this._isMounted) {
            let _role = Array.isArray(data.data) ? data.data : [];
            this.setState({ roles: _role, loading: false });
        }

    }

    onSubmit = e => {
        let error_data = "";

        if (this.state.roleid <= 0) {
            error_data = "Select Role";
            this.props.closeModal()
        }
        else if (this.state.sequences <= 0) {
            error_data = "sequence cannnot be empty";
            this.props.closeModal()
        }
        if (error_data !== "") {
            errorMessage(error_data)
        }
        else if (error_data === "") {
            let app = {

                roleID: parseInt(this.state.roleid),
                sequence: parseInt(this.state.sequences)

            }

            if (this.state.button2 === "Add-Role") {
                this.onSubmitSave(app)
            }
            else if (this.state.button2 === "Update") {
                this.onSubmitUpdate(app)
            }

        }
    }
    onSubmitSave = (data) => {
        //console.log(data);
        this.setState({ loading: true }, () => {
            Post(data, URLAPI.ApprovalRoute, this.onSubmitAfterSave);
        });

    }


    onSubmitAfterSave = (data) => {
        this.props.closeModal()
        if (data.code === 4) {

            successMessage(data.message);

            Get(URLAPI.ReportApproval.Get, this.props.getApproval);

            this.setState({
                loading: false
            })

            //create array of data submitted
            let app = {
                roleID: parseInt(this.state.roleid),
                sequence: parseInt(this.state.sequences),

            };

            //clone the records in array
            let _records = [...this.state.records];

            //update the new record into the array
            _records.push(app);

            //commit the new record into array
            this.setState({ records: _records, roleid: "", sequences: "" });
        }

        else if (data.code === 2) {

            errorMessage(data.message);
            this.setState({ loading: false, });
        }
        else {
            errorMessage("role id or sequence already exist!")
            this.setState({ loading: false, button2: "Add-Role" });
        }
    }


    onSubmitUpdate = (members) => {

        console.log(members);

        if (this.state.roleid) {
            this.setState({ loading: true }, () => {

                Put(members, URLAPI.ReportApproval.Put + "/" + this.state.roleid, this.onSubmitAfterUpdate);
            });
        }
    }
    // onSubmitAfterUpdate = (data) => {
    //     // console.log(data);
    //     this.props.closeModal()
    //     if (data.status === "success") {
    //         this.props.closeModal()

    //         successMessage(data.message);
    //         Get(URLAPI.ReportApproval.Get, this.props.getApproval);
    //         this.setState({ loading: false, button2: "Add-Role" });

    //         //get the index of the edited record
    //         let index = this.state.records.findIndex(x => parseInt(x.id) === parseInt(data.id));

    //         //clone the record
    //         let _records = [...this.state.records];

    //         //check if the record exist
    //         if (index >= 0) {
    //             //update the variables with the new ones
    //             _records[index].roleID = parseInt(this.state.roleid)
    //             _records[index].sequence = parseInt(this.state.sequences)
    //         }

    //         //commit to state
    //         this.setState({ records: _records });

    //     }
    //     else if (data.code === 2) {
    //         errorMessage("role id or sequence already exist")
    //         this.setState({ loading: false, button2: "Add-Role" });
    //     }
    //     else {
    //         errorMessage("Something went wrong while trying to update record Please, try again later.")
    //         this.setState({ loading: false, button2: "Add-Role" });
    //     }
    // }


    componentDidMount() {
        this._isMounted = true;

        Get(URLAPI.ApprovalRoles, this.getSavedRole);
        Get(URLAPI.ReportApproval.Get, this.props.getApproval);
        this.setState({
            roleid: this.props.roleids,
            sequence: this.props.seq
        })

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { loading, loadingbutton } = this.state
        return (<>
            {loading ? <LoadingSpinnerEdo /> :
                <div className="overlay">

                    <button type="button" onClick={this.props.closeModal}>
                        <IoMdClose className={this.state.class2} />
                    </button>
                    <div className="modal-box max-w-400" >
                        <div className="form-group">
                            <div className="content">
                                <select className="w-100 m-b-10" name="roleid" value={this.state.roleid}
                                    onChange={e => this.handleChange(e)} >
                                    <option>select role</option>
                                    {this.state.roles.map(m =>
                                        <option key={m.id} value={m.id}>{m.name}</option>
                                    )}
                                </select>
                                <TextInput
                                    name="sequences"
                                    value={this.state.sequences}
                                    onChange={(e) => this.handleChange(e)}
                                    className="w-100 m-b-10"
                                    label="Sequence"
                                    maxLength={19}
                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                    required
                                />
                                {loading ? <LoadingSpinnerEdo /> :
                                    <button
                                        type="button"
                                        className={this.state.class}
                                        onClick={this.onSubmit}
                                        disabled={loading}
                                    >
                                        {this.state.button2}
                                    </button>
                                }
                                <div className="form-group">
                                    <div className="text-center" style={{ color: "Red" }}> {this.state.errorMessage}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }

        </>);
    }
}

export default ApprovalSetupForm;