/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { CgCalendarDates } from 'react-icons/cg';

const FormDateInput = (props) => (
  <div className={`${props.className} form-group1 form-group-date`}>
    <label htmlFor={props.name}>
      {props.label}
    </label>
    <input
      type={props.type || 'date'}
      name={props.name}
      id={props.name}
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
      onBlur={props.onBlur}
      disabled={props.disabled}
      onKeyPress={props.onKeyPress}
      onKeyDown={props.onKeyDown}
      min={props.min}
      max={props.max}
    />
  </div>
);

export default FormDateInput;
