import React, { Component, memo } from "react";
import TextInput from "../inputs/TextInput";
import HeaderSearch from "../inputs/HeaderSearch";
import LocationDetails from "../layouts/LocationDetails";
import TextareaInput from "../inputs/TextareaInput";
import { URLAPI } from "../../utility/ApiMethods";
import {
  Post,
  Put,
  Delete,
  Get,
  GetwithQueryString,
  searchDocument,
} from "../../utility/fetch";

import notification from "../../utility/notifications";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import {
  successMessage,
  errorMessage,
  deleteMessage,
  confirmToSubmit,
  promptSubmitSave,
} from "../../resources/alert";
import { LoadingSpinnerEdo } from "../../resources/loadingSpinner";
import moment from 'moment'
import axios from "axios";
import Select from "react-select";
import { HiOutlineUpload } from "react-icons/hi";
import { RiSave3Fill } from "react-icons/ri";
import { store } from "react-notifications-component";
import SearchDoc from "../inputs/SearchDoc";
import Cookies from "js-cookie";
import TablePaginationActions from "../inputs/TablePaginationActions";
//import SearchDoc from "../inputs/SearchDoc";

const BASE_API_URL = `https://edogoverp.com/services/api`


const pageTitle = {
  fontSize: "1.3rem",
  font: "font: normal normal 900 19px/25px Avenir;",
};

class ReportGrievance extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      from: "",
      fromId: 0,
      through: "",
      throughId: 0,
      subject: "",
      content: "",
      memoids: 0,
      button: "Submit Request",
      button2: "Save / Submit",
      bgColor: "",
      memoDates: new Date(),
      loading: true,
      memo: [],
      user: [],
      to: [],
      title: [],
      titleId: 0,
      role: [],
      supportingDocument: "",
      docList: [],
      docUrl: [],
      docLink: "",
      toId: 0,
      userid: 0,
      selectedFile: [],
      filepath: [],
      imageArray: [],
      fileMessage: " Upload Supporting Document(s)",
      setSuccess: false,
      setSubmit: false,
      setError: false,
      setDelete: false,
      loadingbutton: false,
      MFilesName: "",
      count: 0,
      searchText: "",
      empty: false,
      rowsPerPage: 10,
      page: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
  }

  handleChange = (e) => {
    console.log(new Date())
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeTo = (toId) => {
    this.setState({ toId }, () =>
      console.log("option selected", this.state.toId)
    );
  };

  handleChangeDoc = (docTitle) => {
    this.setState({ docTitle }, () =>
      console.log("option selected", this.state.docTitle)
    );
  };
  handleChangeTitle = (titleId) => {
    this.setState({ titleId }, () =>
      console.log("option selected", this.state.titleId)
    );
  };

  onFileChange = (event) => {
    var files = event.target.files;
     const supportedTypes = [
      'jpeg',
      'png',
      'gif',
      'pdf',
      "msword",
      "ppt", "pptx", "vnd.ms-excel", "xls", "xlsx",
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const { type, size } = event.target.files[0] ?? '';

    let doc = event.target.files[0];

    console.log('Logging File Type and Size', type, size);
    const fileType = type.slice(type.indexOf('/') + 1);


    let defObj = {
      docTitle: '',
      docPath: ''
    };

    if (!supportedTypes.includes(fileType)){
      notification({
        title: "Upload Error",
        message: "Unsopported file type",
        type: "danger"
      })
      return;
    }
    console.log(files[0],)
    
    let filez = {
      key: this.state.count++,
      files: files[0]
    }
    // if (this.checkFileSize(event)) {
    // if return true allow to setState
    this.setState({
      selectedFile: [...this.state.selectedFile, filez],
      count: this.state.count
    });
    // }
    // console.log(event.target.files[0]);
    console.log(this.state.selectedFile);

  };

  handleChangePage = (event, newPage) => {
    this.setState({ ...this.state, page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ ...this.state, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  checkFileSize = (event) => {
    let files = event.target.files;
    let size = 500000;
    let err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err[x] = files[x].type + "file is too large, please pick a smaller file\n";
      }
    }
    for (var z = 0; z < err.length; z++) {
      errorMessage(err[z]);
      event.target.value = null;
    }
    return true;
  };

  // maxSelectFile = (event) => {
  //     let files = event.target.files // create file object
  //     if (files.length > 3) {
  //         const msg = 'Only 3 images can be uploaded at a time'
  //         event.target.value = null // discard selected file
  //         // alertWarning(msg)
  //         errorMessage(msg)
  //         return false;

  //     }
  //     return true;

  // }

  // checkMimeType = (event) => {
  //     //getting file object
  //     let files = event.target.files
  //     //define message container
  //     let err = ''
  //     // list allow mime type
  //     const types = ['image/png', 'image/jpeg', 'application/msword', 'application/pdf', 'application/vnd.ms-excel', 'application/doc', 'application/docx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  //     // loop access array
  //     for (var x = 0; x < files.length; x++) {
  //         // compare file type find doesn't matach
  //         if (types.every(type => files[x].type !== type)) {
  //             // create error message and assign to container
  //             err += files[x].type + ' is not a supported format\n';
  //         }
  //     };

  //     if (err !== '') { // if message not same old that mean has error
  //         event.target.value = null // discard selected file
  //         // alertWarning(err)
  //         errorMessage(err);
  //         return false;
  //     }
  //     return true;

  // }

  // On file upload (click the upload button)
  onFileUpload(x) {
    const formData = new FormData();
    formData.append(
      "files",
      x.files,
      x?.files?.name
    );

    console.log(formData);
    axios
      .post(
        "https://edogoverp.com/services/api/documents/grievence",
        formData,
      )
      .then((response) => {

        if (response.data.code === 1) {

          // console.log('Upload Progress ' + Math.round(ProgressEvent.loaded / ProgressEvent.total * 100) + '%')

          console.log(response.data.doclink)

          let doc = {
            docPath: response.data.doclink,
            docName: x?.files?.name
          }


          this.setState({
            filepath: [...this.state.filepath, doc]
          });

          let memo = {
            tofk: parseInt(this.state.toId.value),
            fromfk: parseInt(this.state.fromId),
            throughfk: parseInt(this.state.throughId),
            titleID: parseInt(this.state.titleId.value),
            content: this.state.content,
            memoDate: this.state.memoDates.toISOString(),
            documents: [...this.state.filepath || this.state.docUrl, ...this.state.imageArray]
          };



          if (this.state.button2 === "Save / Submit") {
            this.handlePrompSubmitSave();
          } else if (this.state.button2 === "Update") {
            this.onSubmitUpdate(memo);
          }
          console.log("doclinkloog", response.data.doclink);
          store.addNotification({
            title: "Success",
            message: `File ${x?.files?.name} Uploaded Successfully...`,
            type: "success",
            insert: "bottom",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });

        } else {
          notification({
            title: "Error",
            message: "invalid file type please upload supported documents!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
            animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
          });
        }
      })

      .catch((err) => {

        // then print response status
        this.setState({ loadingbutton: false })
        notification({
          title: err,
          message: err.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
          animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
        });
      });
  }

  disablePrevDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  }

  onSubmit = (e) => {
    console.log(this.state.selectedFile)
    e.preventDefault();
    console.log(this.state.memoDates)
    let error_data = "";

    if (this.state.titleId <= 0) {
      notification({
        title: "Input Error:",
        message: "Please Select Title!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
        animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
      })
      error_data = "Select Title:";
    } else if (this.state.toId <= 0) {
      error_data = "Select Recipient:";

      notification({
        title: "Input Error:",
        message: "Please Select Recipient!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
        animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
      })
      /*
        else if (this.state.from === "") {
            error_data = "Please Enter From:";
        }
        */
      // error_data = "Select Recipients:";
    }
    // else if (this.state.through === "") {
    //   error_data = "through must not be empty:";
    // } 
    else if (this.state.content === "") {
      notification({
        title: "Input Error:",
        message: "Please Fill in the content of your Memo!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
        animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
      })
      error_data = "content must not be empty:";
    }
    //  else if (this.state.memoDates === "") {
    //   notification({
    //     title: "Input Error:",
    //     message: "Invalid date, Please input date!",
    //     type: "danger",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
    //     animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
    //   })
    //   error_data = "date must not be empty";
    // }

    // else if (this.state.selectedFile === null) {
    //     error_data = "select a file ";
    // }

    if (error_data !== "") {

      // notification({
      //   title: "Input Error:",
      //   message: `${error_data}`,
      //   type: "danger",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
      //   animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
      // })
      return
      // errorMessage(error_data);
    } else if (error_data === "") {
      this.setState({ loadingbutton: true })
      //check if file is empty or not
      // imageArray:[]
      if (this.state.selectedFile?.length > 0) {
        this.state.selectedFile.map((x) => (
          this.onFileUpload(x)

        ))
      } else {
        this.setState({ loadingbutton: true })
        console.log(this.state.toId)
        let memo = {
          tofk: parseInt(this.state.toId.value),
          fromfk: parseInt(this.state.fromId),
          throughfk: parseInt(this.state.throughId),
          titleID: parseInt(this.state.titleId.value),
          content: this.state.content,
          memoDate: new Date().toISOString(),
          documents: [...this.state.filepath || this.state.docUrl, ...this.state.imageArray]
        };

        if (this.state.button2 === "Save / Submit") {
          this.handlePrompSubmitSave();
        } else if (this.state.button2 === "Update") {
          this.onSubmitUpdate(memo);
        }
      }
    }
  };

  onSubmitSave = (data) => {
    this.setState({ loadingbutton: true, loading: true }, () => {
      Post(data, URLAPI.MemoPost, this.onSubmitAfterSave);
    });
  };

  onSubmitSubmit = (data) => {
    this.setState({ loadingbutton: true, loading: true }, () => {
      Post(data, URLAPI.MemoPost, this.onSubmitAfterSave);
    });
  };

  onSubmitAfterSave = (data) => {
    console.log(data);
    if (data.status === "success") {
      //this.onFileUpload()
      successMessage(data.message);

      Get(URLAPI.MemoGet, this.getMemo);

      this.setState({ loadingbutton: false, loading: false });

      let memo = {
        tofk: parseInt(this.state.toId.value),
        fromfk: parseInt(this.state.fromId),
        throughfk: parseInt(this.state.throughId),
        titleID: parseInt(this.state.titleId.value),
        content: this.state.content,
        memoDate: this.state.memoDates,
        documents: [...this.state.filepath, ...this.state.imageArray]
      };

      //clone the records in array
      let _memo = [...this.state.memo];

      //update the new record into the array
      _memo.push(memo);

      //commit the new record into array
      this.setState({
        memo: _memo,
        loading: false,
        content: "",
        toId: "",
        titleId: "",
        selectedFile: [],
        filepath: [],
        imageArray: [],
        recipientMda: [],
        employeeList: ""
      });
      this.handleCancel()
    } else if (data.status === "failed") {
      errorMessage(data.message);

      this.setState({
        ...this.state,
        loadingbutton: false,
      });
      this.setState({ loading: false, visible: false });
    } else {
      errorMessage(data.message);
      this.setState({
        ...this.state,
        loadingbutton: false,
      });
      this.setState({
        loading: false,
        visible: false,
        through: "",
        throughID: "",
        content: "",
        titleId: "",
        memoDate: '',
        employeeList: [],
        recipientMda: ""
      });
    }
  };

  handleSubmitRequest(memo) {
    this.setState({ loadingbutton: true })
    confirmToSubmit(() => this.onSubmitRequest(memo));
  }

  onSubmitRequest = (memos) => {
    console.log(memos.id);

    if (memos.id !== 0) {
      this.setState({ loading: true }, () => {
        Post(
          memos,
          URLAPI.MemoSubmitRequest + "/" + memos.id,
          this.onSubmitAfterSaveRequest
        );
      });
    }
  };

  onSubmitAfterSaveRequest = (data) => {
    console.log(data);
    if (data.status === "success") {
      successMessage(data.message);

      Get(URLAPI.MemoGet, this.getMemo);
      this.setState({ loading: false });

      // console.log('logging memo id', data.memoId)

      //create array of data submitted
      let req = {
        memoids: data.memoID,
      };

      //clone the records in array
      let _records = [...this.state.memo];

      //update the new record into the array
      _records.push(req);
      this.handleCancel()

      //commit the new record into array
      this.setState({ memo: _records });
    } else if (data.status === "failed") {
      errorMessage(data.message);

      this.setState({ loading: false, visible: false });
    } else {
      errorMessage(data.message);
      this.setState({ loading: false, visible: false });
    }
  };

  handleCancel = () => {
    this.setState({
      button2: "Save / Submit",
      memoids: "",
      toId: "",
      // fromId: item.fromfk,
      titleId: "",
      content: "",
      bgColor: "",
      recipientMda: "",
      employeeList: [],
      selectedFile: [],
      memoDates: new Date(),
      filepath: [],
      imageArray: [],
      loadingbutton: false

    });
  };

  renderButtons = () => {
    const { loadingbutton } = this.state;

    if (this.state.button2 === "Update")
      return (
        <div className="buttonList">
          <button
            onClick={this.onSubmit}
            type="button"
            disabled={loadingbutton}
            className="btn button-large w-10"
          >
            {this.state.button2}
          </button>
          <button
            onClick={this.handleCancel}
            className="btn button-large w-10"
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </button>
        </div>
      );
    else {
      return (
        <div className="buttonList">
          <button
            type="button"
            onClick={this.onSubmit}
            className="btn button-large w-10"
            style={{ width: "400px" }}
            disabled={loadingbutton}
          >
            {this.state.button2}
          </button>
        </div>
      );
    }
  };

  renderMessage = () => {
    if (this.state.button2 === "Update")
      return (
        <div className="bold-text" style={{ ...pageTitle }}>
          Complaints & Grievance Memo On Edit Mode
        </div>
      );
    else {
      return (
        <div className="bold-text" style={{ ...pageTitle }}>
          Complaints & Grievance Memo
        </div>
      );
    }
  };

  onEdit = (item) => {

      if (item != null) {
          console.log(item);
        const title =  this.state.title.filter((data)=> data.label === item.subject)
        const titleId = title[0];

          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

          this.setState({
              button2: "Update",
              memoids: item.id,
              toId: item.tofk,
              //fromId: item.fromfk,
              //throughId: item.throughID,
              // through: item.through,
              titleId: titleId,
              content: item.content,
              //selectedFile: item.docPath,
              bgColor: "lightblue",
              memoDates: item.memoDate

          })
      }

  }

  onSubmitUpdate = (memo) => {

      if (this.state.memoids) {

          this.setState({ loading: true }, () => {

              //console.log("about to call put endpoint");

              Put(memo, URLAPI.MemoPut + "/" + this.state.memoids, this.onSubmitAfterUpdate);
          });
      }
  }

  onSubmitAfterUpdate = (data) => {
      //console.log(data);

      if (data.status === "success") {

          successMessage(data.message)

          Get(URLAPI.MemoGet, this.getMemo)
          this.setState({
              ...this.state,
              loadingbutton: false
          })

          this.setState({ loading: false });

          //get the index of the edited record
          let index = this.state.memo.findIndex(x => parseInt(x.id) === parseInt(data.id));

          //clone the record
          let _memo = [...this.state.memo];

          //check if the record exist
          if (index >= 0) {
              //update the variables with the new ones
              _memo[index].tofk = parseInt(this.state.toId.value)
              _memo[index].fromfk = parseInt(this.state.fromId)
              // _memo[index].throughfk = parseInt(this.state.throughId)
              _memo[index].from = this.state.from
              // _memo[index].through = this.state.through
              _memo[index].titleID = parseInt(this.state.titleId.value)
              _memo[index].content = this.state.content
              _memo[index].memoDate = this.state.memoDate;

          }

          //commit to state
          this.setState({
              memo: _memo, content: "", titleId: "", fromId: "",recipientMda: 0,
              employeeList: [], memoDates: "", subject: "", toId: "", selectedFile: null,
              bgColor: "", button2: "continue"
          });

          //this.handleCancel();
      }

      else {
          this.setState({
              ...this.state,
              setError: true
          })
          this.setState({ loading: false, visible: false });
      }

  }

  //handle delete record

  handleDeleteRecord(record) {
    deleteMessage(() => this.onDelete(record));
  }

  handleDeleteFile = (y) => {
    let pp = this.state.selectedFile.filter((x, index) => (

      x.key !== y
    ))
    this.setState({
      selectedFile: pp,
    });
  };
  handleDeleteMFile = (y) => {
    let pp = this.state.imageArray.filter((x, index) => (

      x.key !== y
    ))
    this.setState({
      imageArray: pp,
    });
  };

  onDelete = (record) => {
    if (record !== "") {
      this.setState({ loading: true }, () => {
        Delete(URLAPI.MemoDelete + "/" + record.id, this.onAfterDelete);
      });
    }
  };

  onAfterDelete = (response) => {
    if (response.status === "success") {
      successMessage(response.message);

      Get(URLAPI.MemoGet, this.getMemo);

      this.setState({
        memo: this.state.memo.filter((rec) => rec.id !== response.id),
        throughId: "",
        content: "",
        titleId: "",
        fromId: "",
        memoDates: new Date(),
        subject: "",
        toId: "",
        selectedFile: [],
        filepath: [],
        imageArray: [],
        recipientMda: "",
        employeeList: [],
        mdaList: [],
        employeeName: '',
        
        employees: [],

        fileMessage: " Upload Supporting Document(s)",
        loading: false,
      });
      // this.handleCancel();
    } else {
      errorMessage(response.message);
      this.setState({ loading: false });
    }
  };

  getMdas = () => {
    axios
      .get(`${BASE_API_URL}/Employees/mdas`)
      .then((response) => {

        console.log("--mdas--")
        console.log(response?.data)
        // this.setState({
        const roleList = response?.data?.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        this.setState({ mdaList: roleList })

        // });
      })
  }

  getEmployeesFromMda = (id) => {
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {

        console.log("--employeesByNads--")
        console.log(response?.data)
        // this.setState({
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
        // setEmployeeList(roleList.filter((item)=>{return !item.name.includes(Cookies.get('fullname'))}));
        this.setState({ employees: roleList })

        // setMdaList(roleList)

        // });
      })
  }


  getMemo = (data) => {
    console.log("Memos:", data);

    if (this._isMounted) {
      let _memo = Array.isArray(data.data) ? data.data : [];
      this.setState({ memo: _memo.filter((item)=>item?.createdBy === Cookies.get("_FullName")), loading: false });
    }
  };

  // fetchDocument = (data) => {
  //   console.log("docs:", data);
  //   let _docs = Array.isArray(data) ? data : [];
  //   let records = [];

  //   if (_docs != "") {
  //     _docs.forEach(function (l, index) {

  //       let sn = { value: l.title, label: l.fileurl, };
  //       records.push(sn);
  //     });
  //   }
  //   this.setState({ docUrl: records, loading: false });
  // };

  getUser = (data) => {
    console.log("login data:", data);
    if (this._isMounted) {
      try {
        this.setState({
          loading: false,
          through: data.data.through,
          throughId: data.data.throughID,
          from: data.data.from,
          fromId: data.data.fromID,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  fetchTo = (data) => {
    let _tos = Array.isArray(data.data) ? data.data : [];

    let records = [];

    if (_tos != "") {
      _tos.forEach(function (l, index) {
        let sn = { value: l.userId, label: l.role };
        records.push(sn);
      });
    }
    this.setState({ to: records, loading: false });
  };

  fetchTitle = (data) => {
    let _title = Array.isArray(data.data) ? data.data : [];

    let records = [];

    if (_title != "") {
      _title.forEach(function (l, index) {
        let sn = { value: l.id, label: l.grievanceType };
        records.push(sn);
      });
    }
    this.setState({ title: records, loading: false });
  };

  componentDidMount() {
    this._isMounted = true;
    this.getMdas()
    // getEmployeesFromMda
    console.log(Cookies.get("userId"))

    Get(URLAPI.MemoGet, this.getMemo);
    Get(URLAPI.MemoFetchUser, this.getUser);
    Get(URLAPI.MemoTo, this.fetchTo);
    Get(URLAPI.ReportGrievance.Get, this.fetchTitle);
    //SearchDoc(URLAPI.searchDocument, this.fetchDocument);
    this.searchDoc()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fileData = () => {
    if (this.state.selectedFile?.length > 0) {
      return (
        this.state.selectedFile?.map((x, index) => (
          <div key={x?.key}>
            <br />
            <a href="#">
              <p style={{ color: "#000000", fontSize: "14px" }}>
                File Name: {x?.files.name}
                <GiTrashCan
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleDeleteFile(x.key)}
                />
              </p>
            </a>
          </div>
        ))

      );
    } else {
      return (
        <div>
          <br />
          <span style={{ color: "red" }}>
            Supported docs! (PDF, WORD, EXCEL,POWERPOINT & JPG)
          </span>
        </div>
      );
    }
  };

  handlePrompSubmitSave = () => {
    let create = {
      tofk: parseInt(this.state.toId.value),
      fromfk: parseInt(this.state.fromId),
      // throughfk: parseInt(this.state.throughId),
      titleID: parseInt(this.state.titleId.value),
      content: this.state.content,
      memoDate: this.state.memoDates,
      actionState: 0,
      documents: [...this.state.filepath, ...this.state.imageArray]
    };

    let submit = {
      tofk: parseInt(this.state.toId.value),
      fromfk: parseInt(this.state.fromId),
      // throughfk: parseInt(this.state.throughId),
      titleID: parseInt(this.state.titleId.value),
      content: this.state.content,
      memoDate: this.state.memoDates,
      actionState: 1,
      documents: [...this.state.filepath, ...this.state.imageArray]
    };
    this.setState({ loadingbutton: false })
    promptSubmitSave(
      () => this.onSubmitSave(create),

      () => this.onSubmitSubmit(submit)
    );
  };
  mFilesFunc = (val) => {
    // console.log(val)
    this.searchDoc(val)
  }
  holdValues = (filename, fileurl) => {
    let holder = {
      key: this.state.count++,
      docPath: fileurl,
      docName: filename
    }
    this.setState({
      imageArray: [...this.state.imageArray, holder],
      count: this.state.count
    })
    this.setState({ docList: [] })
    console.log(this.state.imageArray)
  }
  searchDoc = async (title) => {
    const userId = `${Cookies.get("userId")}`;
    console.log('Logging ID', userId);

    const res = await searchDocument(userId, title);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;

      if (data.length > 0) {
        // setDocList(data);
        this.setState({
          docList: data,
        });
      } else if (data.code === 0 || data.code === 1) {
        // setDocList([]);
        this.setState({
          docList: [],
        });
        notification({
          title: "Document Search Error",
          message: data.mesage,
          type: "danger",
          duration: 5000,
        });
      } else {
        // setDocList([]);
        this.setState({
          docList: [],
        });
      }
    }
  };

  handleStateChange = (name, value) => {
    this.setState({ [name]: value });
    console.log('stateValue', name)
  };

  render() {
    const { loading, page, rowsPerPage, memo } = this.state;

    const tableData =
      rowsPerPage > 0
        ? memo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : memo;

    return (
      <>
        {loading ? (
          <LoadingSpinnerEdo />
        ) : (
          <div className="w-100">
            <div className="flex flex-v-center space-between">
              {this.renderMessage()}
              <LocationDetails />
            </div>
            <br />
            <div className="card" style={{ backgroundColor: "white" }}>
              <div className="card-body">
                <div className="form-group">
                  <div className="row">
                    <div
                      className="col-8 "
                      style={{ background: this.state.bgColor }}
                    >
                      <div className="w-100 m-b-10 form-group ">
                        <span>Select Grievance Title</span><span className="color-red size-large">*</span>
                        <Select
                          value={this.state.titleId}
                          options={this.state.title.map((item) => ({ label: item.label.toUpperCase(), value: item.value }))}
                          placeholder="Select Title"
                          onChange={(e) => this.handleChangeTitle(e)}
                          autoComplete="on"
                        />
                      </div>

                      <p>Grievance Initiator</p>
                      <TextInput
                        name="from"
                        value={this.state.from}
                        onChange={(e) => this.handleChange(e)}
                        className="w-100 m-b-10 form-group "
                        // label="From:"
                        autoComplete="on"
                        disabled
                      />
                      {/* <TextInput
                        name="through"
                        value={this.state.through}
                        onChange={(e) => this.handleChange(e)}
                        className="w-100 m-b-10 form-group"
                        label="Through:"
                        autoComplete="on"
                       
                      /> */}

                      {/* <br /> */}
                      <br />

                      <div className="" style={{ width: "100%" }}>
                        <span>Select Recipient MDA</span><span className="color-red size-large">*</span>
                        <Select
                          // isMulti
                          className="basic-multi-select"
                          onChange={(value) => {
                            console.log(value)
                            // this.setState({ employeeName: e.target.value });
                            this.getEmployeesFromMda(value.value)
                            this.setState({ recipientMda: value });
                          }}
                          value={this.state.recipientMda}
                          options={this?.state?.mdaList?.map((m) => ({
                            label: m?.name,
                            value: m?.value,
                          }))}
                        />

                        <span>Select Recipient </span><span className="color-red size-large">*</span>
                        <Select
                          // isMulti
                          className="basic-multi-select m-t-10 m-b-5"
                          onChange={(value) => {
                            console.log(value.value)
                            this.setState({ employeeName: value.value });
                            this.setState({ employeeList: value });
                            this.handleChangeTo(value)


                            // this.getEmployeesFromMda(value.value)
                            // this.setState({recipientMda:value});
                          }}
                          value={this.state.employeeList}
                          placeholder="Select Recipient"

                          options={this.state.employees?.map((m) => ({
                            label: m.name,
                            value: m.value,
                          }))}
                        />
                        {/* <AutoComplete
                  label="Recipient MDA*"
                  options={mdaList || []}
                  value={recipientMda}
                  // isShow={false}
                  onChange={(value) => {
                    setRecipientMda(value)
                    getEmployeesFromMda(value)
                  
                  }}
                  className="w-100 m-b-10"
                /> */}
                      </div>
                      {/* <div className="w-100 m-b-10 form-group ">
                        <Select
                          value={this.state.toId}
                          options={this.state.to}
                          placeholder="Select Recipients"
                          onChange={(e) => this.handleChangeTo(e)}
                          autoComplete="on"
                        />
                      </div> */}
                      <br />
                      <br />

                      <span>Memo Content: </span><span className="color-red size-large">*</span>
                      <TextareaInput
                        name="content"
                        value={this.state.content}
                        onChange={(e) => this.handleChange(e)}
                        className="w-100 m-b-10 form-group "
                        // label="Memo-Content:"
                        autoComplete="on"
                      />
                    </div>
                    <div
                      className="col-4"
                      style={{ background: this.state.bgColor }}
                    >
                      {/* <FormDateInput
                        name="memoDates"
                        type="date"
                        value={this.state.memoDates}
                        onChange={(e) => this.handleChange(e)}
                        className="w-100 m-b-10 form-group "
                        label="Memo Date"
                        min="2020-01-01"
                        max="2029-12-31"
                      />

                      <br /> */}

                      <label
                        className="custom-file-upload "
                        style={{ backgroundColor: "#f7cd15", color: "black" }}
                      >
                        <input
                          type="file"
                          className="w-100 m-b-10 custom-file-upload"
                          name="file"
                          multiple
                          onChange={this.onFileChange}
                        />
                        <HiOutlineUpload />
                        {this.state.fileMessage}
                      </label>
                      {this.fileData()}
                      <br />

                      <div className="w-100 m-b-10 form-group ">

                      </div>
                      <SearchDoc
                        className=""
                        label="Search For Document"
                        value={
                          this.state.docTitle ||
                          this.state.supportingDocument?.docTitle ||
                          ""
                        }
                        docList={this.state.docList}
                        mFilesFunc={this.mFilesFunc}
                        setEmpty={this.state.empty}
                      // onChange={(e) => {
                      //   e.target.value.length === 0 &&
                      //     this.setState({ docList: [] });
                      //   e.target.value.length === 0 &&
                      //     this.setState({ docLink: "" });
                      //   e.target.value.length === 0 &&
                      //     this.setState({ supportingDocument: {} });
                      //   this.setState({ docTitle: e.target.value });
                      //   e.target.value.length > 1 &&
                      //     this.searchDoc(e.target.value);
                      // }}
                      // setDocList={this.handleStateChange}
                      // setDocTitle={this.handleStateChange}
                      // setState
                      // setStateValue={this.handleStateChange}
                      // formState={this.state.supportingDocument}
                      // stateName2={this.state.docPath}
                      // stateName={this.state.docTitle}
                      // setSelectedValue={this.handleStateChange}
                      // disabled={this.state.supportingDocument?.docPath}
                      />
                      <div>
                        {this.state.docList.map((x) => (
                          <div className='deletezz' style={{ padding: "5px", border: "2px solid  #c6c6c6", display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => {

                            this.holdValues(x.title, x.fileurl)
                            this.setState({ empty: !this.state.empty })

                          }}>
                            <p>
                              {x.title}
                            </p>
                            {/* <div><GiTrashCan/></div> */}
                          </div>
                        ))}
                        <div >
                          <p style={{ paddingTop: "10px" }}>Mfiles selected documents</p>
                          {this.state.imageArray.map((x) => (
                            <div className='deletez' style={{ padding: "5px", border: "2px solid #c6c6c6", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }} onClick={() => {

                              // this.holdValues(x.title,x.fileurl)
                              // this.setState({empty:!this.state.empty})

                            }}>
                              <a href={x.docFile}>
                                {x.docName}
                              </a>
                              <div onClick={() => this.handleDeleteMFile(x.key)}><GiTrashCan style={{ fontSize: "16px" }} /></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.renderButtons()}
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="flex w-100 space-between">
              <h3>Saved Report(s)</h3>
              <HeaderSearch className="search-border" onChange={(e) => this.setState({ searchText: e?.target?.value })} />
            </div>
            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p className="san-sherif">To</p>
                  </div>
                  <div className="th">
                    <p className="san-sherif">From</p>
                  </div>
                  {/* <div className="th">
                    <p className="san-sherif">Through</p>
                  </div> */}
                  <div className="th">
                    <p className="san-sherif">Title</p>
                  </div>
                  <div className="th">
                    <p className="san-sherif">MemoDate</p>
                  </div>
                  <div className="th right-text">
                    <p className="san-sherif">Actions</p>
                  </div>
                </div>
              </div>
              {this.state.memo.length === 0 ? (
                <h3>No memo has been created</h3>
              ) : (

                <div className="table-body">
                  {tableData?.sort((a, b) => {
                    const dateA = new Date(a.memoDate);
                    const dateB = new Date(b.memoDate);
                    console.log(new Date(dateA));
                    return dateB - dateA;
                  })
                  .filter((memo) => { return memo?.to?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()) || memo?.from?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()) || memo?.subject?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()) })
                  .map((item) => (
                    <div className="table-row" key={item.id}>
                      <div className="td">{item.to}</div>
                      <div className="td">{item.from}</div>

                      <div className="td">{item.subject?.toUpperCase()}</div>
                      <div className="td">{typeof item.memoDate === 'string' && item.memoDate}</div>
                      <div className="td actions">
                        <button
                          type="button"
                          className="m-l-10 pointer"
                          onClick={() => this.handleSubmitRequest(item)}
                        >
                          <div className="tooltip">
                            <RiSave3Fill />
                            <span className="tooltiptext">Submit Memo</span>
                          </div>
                        </button>
                        <button type="button" className="m-l-10 pointer" onClick={() => this.onEdit(item)}>
                                                <div className="tooltip">
                                                    <BiEditAlt />
                                                    <span className="tooltiptext">Edit Memo</span>
                                                </div>
                                            </button>
                        <button
                          type="button"
                          className="m-l-10 del pointer"
                          onClick={() => this.handleDeleteRecord(item)}
                        >
                          <div className="tooltip">
                            <GiTrashCan />
                            <span className="tooltiptext">Delete Memo</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  ))}
                  {this?.state?.memo?.length > 10 && (
                    <div className="w-100 ">
                      <TablePaginationActions
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        count={this?.state?.memo?.length}
                        changeRowsPerPage={this.handleChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ReportGrievance;
