
import React, { Component } from 'react'
// icons
import { URLAPI } from '../../utility/ApiMethods'
import { Get } from '../../utility/fetch'
import TextInput from "../inputs/TextInput";
import { BiMailSend, BiTrash } from "react-icons/bi";
import TextareaInput from "../inputs/TextareaInput";
import imageSrc from '../../assets/images/profile-pix.jpg'
import { LoadingSpinnerEdo, LoadingSpinner } from '../../resources/loadingSpinner';
import { GoCalendar } from "react-icons/go";
import Cookies from 'js-cookie';
import PictureModal from '../layouts/PictureModal';


class DashboardDetails extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            from: "",
            fromId: 0,
            through: "",
            throughId: 0,
            to: "",
            user: "",
            comment: "",
            content: "",
            toId: 0,
            showPicture: false,
            picture: "",
            subject: "",
            docs: "",
            memoid: 0,
            button: "Approve",
            loadingbutton: false,
            statistics: []
        }
        this.handleChange = this.handleChange.bind(this);
        //this.setState = this.setState.bind(this);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });

    }

    showImage = (path) => {
        this.setState({ showPicture: true });
        this.setState({ picture: path });
    }

    closeModal = () => {
        this.setState({ showPicture: false });
    }

    supportedTypes = [
        'jpeg',
        'png',
        "jpg",
        "JPEG",
        "PNG",
        "JPG"
    ];



    componentDidMount() {
        console.log(this.props.documents)

        this._isMounted = true;

        this.setState({
            memoid: this.props.id,
            to: this.props.to,
            from: this.props.initiator,
            memoDate: this.props.initiatedAt,
            through: this.props.role,
            content: this.props.content,
            subject: this.props.title,
            // docs: this.props.docPath,
            comment: this.props.comment
            //memo: this.props.filterReviewsOnApprover

        })

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        const { loading } = this.state

        return (<>
            {loading ? <LoadingSpinnerEdo /> :
                <div className="m-t-10" style={{ transition: "all 0.6s ease-in" }}>
                    <div className="flex">
                        <div className="flex flex-direction-column col-3 no-padding">
                            <div className="no-padding">
                                <div
                                    style={{
                                        boxShadow: "0px 2px 6px #0000000A",
                                        backgroundColor: "white",
                                        // padding: "0",
                                    }}
                                >
                                    <div style={{ borderBottom: "1px solid rgba(108,108,108,.4)" }}>
                                        <h4 className="bold-text" style={{ padding: ".8rem" }}>
                                            Subject: {this.props.subject}
                                        </h4>
                                    </div>
                                    <div style={{ padding: ".8rem" }}>
                                        <div className="flex">
                                            <h5 className="bold-text" style={{ color: "#909090" }}>
                                                Memo Initiation Date:
                                            </h5>
                                            <p style={{ color: "#909090", fontSize: "12px" }}>
                                                {this.state.memoDate}
                                            </p>
                                            <GoCalendar
                                                color="#909090"
                                                className="m-l-auto my-auto"
                                                fontSize="20px"
                                                style={{ position: "relative", bottom: "5px" }}
                                            />
                                        </div>
                                        <div className="flex m-t-10">
                                            <h5 className="bold-text" style={{ color: "#909090" }}>
                                                Memo Initiator: {this.state.from}
                                            </h5>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-9 pd-r-0">
                            <div
                                className="p-20"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                    // padding: "0",
                                }}
                            >
                                <div>
                                    {/* <TextInput
                                        label="To"
                                        name="to"
                                        value={this.state.to}
                                        disabled
                                    /> */}
                                    <TextInput
                                        label="from"
                                        name="from"
                                        value={this.state.from}
                                        disabled
                                    />

                                    <TextInput
                                        label="To"
                                        name="through"
                                        value={this.state.through}
                                        disabled
                                    />
                                </div>


                                <div>
                                    {
                                        this.props.status !== "Approved" ?
                                            <div className="m-t-10">
                                                <TextareaInput
                                                    label="Detail"
                                                    name="Detail"
                                                    value={this.state.content}
                                                    disabled
                                                >
                                                </TextareaInput>
                                                <br />
                                            </div>
                                            :
                                            <div>

                                                <div className="m-t-10">
                                                    <TextareaInput
                                                        label="Detail"
                                                        name="Detail"
                                                        value={this.state.content}
                                                        disabled
                                                    >
                                                    </TextareaInput>
                                                    <br />
                                                </div>
                                                <div className="m-t-10">
                                                    <TextareaInput
                                                        label="Approver's Comment"
                                                        name="Comment"
                                                        value={this.props?.comment[0]?.comment}
                                                        disabled
                                                    >
                                                    </TextareaInput>
                                                    <br />
                                                </div>

                                            </div>

                                    }

                                </div>
                                <div className="m-t-20">
                                    <h3 className="m-t-20" style={{ fontWeight: "bold" }}><strong>Supporting Documents</strong></h3>
                                    {this.props.documents.map((x) => (

                                        this.supportedTypes.includes(x.docName.slice(x.docName.indexOf('.') + 1)) ?
                                            <i style={{ display: "flex" }}><BiMailSend /><div onClick={() => this.showImage(x.docPath)} style={{ display: "flex", color: 'blue' }} className="m-l-10 pointer">

                                                <h3 style={{ marginLeft: "5px" }}>
                                                    {x.docName}

                                                </h3>
                                            </div> </i>
                                            :
                                            <i style={{ display: "flex" }}><BiMailSend /><a href={`${x.docPath}`} style={{ display: "flex" }} download={x.docName} target="_blank" className="m-l-10">
                                                <h3 style={{ marginLeft: "5px" }}>
                                                    {x.docName}
                                                </h3>
                                            </a> </i>
                                    ))
                                    }

                                </div>


                                {/* href={`${x.docPath}`} */}






                                {/* <div className="m-t-20"> */}
                                {/* {this.props.documents.length>0 && <h3 className="m-t-20" style={{ fontWeight: "bold" }}><strong>uploaded Documents</strong></h3>} */}
                                {/* { this.props.documents.map((x)=>( */}
                                {/* <i style={{display:"flex"}}><BiMailSend /><a href={`${x.docPath}`} style={{display:"flex"}}  download={x.docName} target="_blank" className="m-l-10">  */}
                                {/* <h3 style={{marginLeft:"5px"}}> */}
                                {/* {x.docName} */}
                                {/* </h3> */}
                                {/* </a>*<BiTrash /> </i> */}
                                {/* )) */}
                                {/* } */}

                                {/* </div> */}



                                {/* <div className="m-t-10">
                                    <TextareaInput
                                        label="Content"
                                        name="content"
                                        value={this.state.content}
                                        disabled
                                    >
                                    </TextareaInput>
                                    <br />

                                </div> */}
                                {/* <div className="m-t-20">
                                    <h3 className="m-t-20" style={{ fontWeight: "bold" }}><strong>Supporting Documents</strong></h3>
                                    <i><BiMailSend /><a href={`${Cookies.get('_APIBaseURL') +  this.state.docs}`} download={this.state.docs} target="_blank" className="m-l-10">click to view uploaded document</a>{/**<BiTrash /> </i>

                                </div> */}


                            </div>

                            <div
                                className="m-t-10"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                }}
                            >
                                {/* <div
                                    className="p-20"
                                    style={{ borderBottom: "1px solid rgba(108,108,108,.4)" }}
                                >
                                    {this.props.comment.map(item =>
                                        <div className="flex space-between" key={item.id}>
                                            <div className="flex flex-v-center">
                                                <div
                                                    style={{
                                                        border: "1px solid #B2D4B2",
                                                        color: "#6F6F6F",
                                                        backgroundColor: "#C0DBC031",
                                                        borderRadius: "2px",
                                                        padding: "7px",
                                                    }}
                                                >
                                                    Supervisor
                                                </div>
                                                <p className="m-l-5">{item.user}</p>
                                            </div>
                                            <div className="p-20">
                                                <div className="flex bold-text m-t-10" style={{ color: "#1C811C", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                    <h4>Comment</h4>
                                                </div>
                                                <div
                                                    className="m-b-10 m-t-5"
                                                    style={{
                                                        color: "#43425D",
                                                        fontSize: "13px",
                                                        lineHeight: "1.3",
                                                    }}
                                                >
                                                    <p style={{ justifyContent: "center", alignItems: "center", textAlign: "center", display: "flex" }}>
                                                        {this.props.comment.length === 0 ? <p>No available comment</p> : item.comment}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex">
                                                <div
                                                    className="flex flex-direction-column m-r-5 m-t-10"
                                                    style={{ textAlign: "right" }}
                                                >
                                                    <br />
                                                    <h4 className="bold-text m-t-8">{item.user}</h4>
                                                    <p style={{ color: "#909090" }}>
                                                        {item.role}
                                                    </p>
                                                </div>
                                                <div style={{ position: "relative", bottom: "3px" }} className="m-t-10">
                                                    <br />
                                                    <img
                                                        src={imageSrc}
                                                        alt=""
                                                        width="40px"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
 */}

                            </div>
                            <div
                                className="m-t-10"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                }}
                            >
                            </div>

                            {(this.props?.comments?.length > 0) && <div
                                className="p-20"
                                style={{ borderBottom: "1px solid rgba(108,108,108,.4)", backgroundColor: "white" }}
                            >
                                {this.props?.comments?.map(item =>
                                    <div className="flex space-between" key={item.id}>
                                        <div className="flex flex-v-center">

                                            <div className="flex" style={{ alignItems: "center" }}>
                                                <div style={{ position: "relative", bottom: "3px", marginRight: "20px", display: "flex", flexDirection: "column", alignItems: "center" }} className="m-t-10">
                                                    <br />
                                                    <img
                                                        src={imageSrc}
                                                        alt=""
                                                        width="40px"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                    <div
                                                        style={{
                                                            border: "1px solid #B2D4B2",
                                                            color: "#6F6F6F",
                                                            backgroundColor: "#C0DBC031",
                                                            borderRadius: "2px",
                                                            padding: "7px",
                                                            marginTop: "5px"
                                                        }}
                                                    >
                                                        Supervisor
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex flex-direction-column m-r-5 m-t-10"
                                                    style={{ textAlign: "left" }}
                                                >
                                                    <br />
                                                    <h4 className="bold-text m-t-8">{item.user}</h4>
                                                    <p style={{ color: "#909090" }}>
                                                        {item.role}
                                                    </p>
                                                </div>

                                            </div>

                                            <p className="m-l-5">{item.user}</p>
                                        </div>
                                        <div className="" style={{ padding: "20px 0" }}>
                                            <div className="flex bold-text m-t-10" style={{ color: "#1C811C", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                <h4>Comment</h4>
                                            </div>
                                            <div
                                                className="m-b-10 m-t-5"
                                                style={{
                                                    color: "#43425D",
                                                    fontSize: "13px",
                                                    lineHeight: "1.3",
                                                }}
                                            >
                                                <p style={{ justifyContent: "center", alignItems: "center", textAlign: "center", display: "flex", fontSize: "17px", color: "#909090", }}>
                                                    {this.props?.comment?.length === 0 ? <p>No available comment</p> : item?.comment}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>}

                        </div>
                    </div>
                </div>

            }
            {this.state.showPicture && <PictureModal closeModal={this.closeModal} picture={this.state.picture} />}
            {this.state.loadingbutton ? <LoadingSpinnerEdo /> : null}
        </>

        );
    }
}

export default DashboardDetails;
