import React from 'react';


var year = new Date().getFullYear();

const Footer = () => (

  <footer>
    <div className="content space-between flex-v-center">
      <div className="footer-inner-left">
        <p>{year} Edo State Government Digital Office</p>
      </div>
    </div>
  </footer>
);

export default Footer;
