import React, { Component } from 'react'
import ApprovalSetupForm from '../layouts/ApprovalSetupForm';
import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import LocationDetails from '../layouts/LocationDetails'
import { URLAPI } from '../../utility/ApiMethods'
import { Put, Delete, Get } from '../../utility/fetch';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';

const pageTitle = {
    fontSize: "1.3rem",
};


class ApprovalSetup extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            roleidd: 0,
            sequences: 0,
            visible: false,
            formErrors: {},
            errorMessage: "",
            className: '',
            class: "add-button pointer",
            class2: "pointer",
            button: "Add-Role",
            loading: true,
            visible: false,
            approvals: [],
            setDelete: false,

        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    openModal = () => {
        if (this.state.button == 'Add-Role')
            this.setState({
                visible: true
            })
    }
    closeModal = () => {
        this.setState({ visible: false });

    }


    // openModalOnEdit = (record) => {
    //     //set record to state
    //     if (record !== null) {
    //         this.setState({
    //             button: 'Update',
    //             roleidd: record.roleID,
    //             sequences: record.sequence,
    //             visible: true
    //         })
    //     }
    // }


    getApproval = (data) => {
        console.log(data);
        if (this._isMounted) {
            let _approvals = Array.isArray(data.data) ? data.data : [];
            this.setState({ approvals: _approvals, loading: false });
        }

    }


    componentDidMount() {
        this._isMounted = true;
        // Get(URLAPI.ApprovalRoles, this.getSavedRole);
        Get(URLAPI.ReportApproval.Get, this.getApproval);
    }



    // componentDidUpdate() {
    //     Get(URLAPI.ReportApproval.Get, this.getApproval);
    // }


    componentWillUnmount() {
        this._isMounted = false;
    }

    //handle delete record
    handleDeleteRecord(record) {

        deleteMessage(
            () => this.onDelete(record)
        )
    }


    onDelete = (record) => {

        if (record !== "") {
            this.setState({ loading: true }, () => {
                Delete(URLAPI.ReportApproval.Delete + "/" + record.id, this.onAfterDelete);
            });
        }
    }

    onAfterDelete = (response) => {
        if (response.code === 1) {


            successMessage(response.message)

            Get(URLAPI.ReportApproval.Get, this.getApproval);

            this.setState({ loading: false })

            this.setState({ approvals: this.state.approvals.filter((rec) => rec.id !== response.id) });


        }
        else {

            errorMessage(response.message)
        }

    }


    render() {
        const { loading } = this.state
        return (<>

            {loading ? <LoadingSpinnerEdo /> :
                <div className="w-100">
                    <div className="flex flex-v-center space-between">
                        <div className="bold-text" style={{ ...pageTitle }}>
                            Approval Route
                 </div>
                        <LocationDetails />
                    </div>
                    <div className="table-view m-t-10">
                        <div className="table-header">
                            <div className="table-row">
                                <div className="th"><p className="san-sherif">Role</p></div>
                                <div className="th"><p className="san-sherif">Sequence</p></div>
                                <div className="th"><p className="san-sherif">Created By</p></div>
                                <div className="th"><p className="san-sherif">Created At</p></div>

                                <div className="th">
                                    <button className={this.state.class} type="button" onClick={this.openModal}>
                                        <RiAddLine />
                                        {this.state.button}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-body">
                            {this.state.approvals.map((m) =>
                                <div className="table-row" key={m.id}>
                                    <div className="td">{m.role}</div>
                                    <div className="td">{m.sequence}</div>
                                    <div className="td">{m.createdBy}</div>
                                    <div className="td">{m.createdAt}</div>

                                    <div className="td actions">
                                        {/* <button type="button" className={this.state.class2} onClick={() => this.openModalOnEdit(m)}>
                                            <div className="tooltip">
                                                <BiEditAlt />
                                                <span className="tooltiptext">Edit</span>
                                            </div>
                                        </button> */}
                                        <button type="button" className="m-l-10 del pointer" onClick={() => this.handleDeleteRecord(m)}>
                                            <div className="tooltip">
                                                <GiTrashCan />
                                                <span className="tooltiptext">Delete</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            )}

                        </div>
                        {this.state.visible ? <ApprovalSetupForm buttons={this.state.button} visibles={this.state.visible} roleids={this.state.roleidd} seq={this.state.sequences} getApproval={this.getApproval} closeModal={() => this.setState({ visible: false })} /> : null}
                    </div>



                </div>
            }

        </>);
    }
}

export default ApprovalSetup;