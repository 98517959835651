import React, { Component } from 'react'
import LocationDetails from '../layouts/LocationDetails';
import SearchInput from '../inputs/SearchInput'
// import { BsChevronRight } from "react-icons/bs";
// import { BsChevronLeft } from "react-icons/bs";
import TransactionCard from '../layouts/TransactionCard';
import { URLAPI } from '../../utility/ApiMethods'
import { Get } from '../../utility/fetch'
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';
import { SwapTableContext } from '../Contexts/SwapTableContext';


const pageTitle = {
    fontSize: "1.3rem",
};

const tableHead = {
    padding: "15px",
    textAlign: "left"
};

const centerContent = {
    textAlign: "center",
};

class GrievanceReviews extends Component {
    static contextType = SwapTableContext;

    _isMounted = false;
    constructor(props) {

        super(props)
        // this.props.func = this.props.func.bind(this);
        this.state = {
            loading: true,
            reviews: [],
            reviewsOriginal: [],
            reviewsA: [],
            search: null,
            isTreated: false,


        }
        this.filterReviewsOnApprover = this.filterReviewsOnApprover.bind(this)
        this.getReviews = this.getReviews.bind(this);
        this.getApproved = this.getApproved.bind(this);
    }




    searchSpace = (event) => {
        let keyword = event.target.value;
        this.setState({ search: keyword })
    }

    collectort = (tableA) => {
        this.setState({ reviews: tableA, loading: false });
    }

    getReviews = (data) => {
        const { handlePopulate, populate, numba, handleNumba } = this.context

        console.log("logging data", data.data)

        if (this._isMounted) {

            let _reviews = Array.isArray(data.data) ? data.data : [];

            this.setState({ reviews: _reviews, loading: false });
            this.setState({ reviewsOriginal: _reviews, loading: false });
            handleNumba(false)



        }

    }

    getApproved = (data) => {
        const { handlePopulate, populate, numba, handleNumba } = this.context

        console.log("logging Approved data", data.data)

        if (this._isMounted) {

            let _reviews = Array.isArray(data.data) ? data.data : [];
            this.setState({ reviewsA: _reviews, loading: false });
            // handleNumba(false)
        }

    }


    filterReviewsOnApprover = (memoid) => {
        this.setState({ reviews: this.state.reviews.filter((rec) => rec.id !== parseInt(memoid)) })
    }

    componentDidMount() {
        this._isMounted = true;

        Get(URLAPI.GrievanceReview.Get, this.getReviews);
        Get(URLAPI.GrievanceReview.GetTreated, this.getApproved);
    }
    handlePending = () => {
        this.setState({ reviews: this.state.reviewsOriginal })
        this.setState({ isTreated: false });
        console.log(this.state.isTreated)

    }
    handleApproved = () => {
        console.log(this.state.reviewsA)
        this.setState({ reviews: this.state.reviewsA?.sort((a, b) => {
            const dateA = new Date(a?.initiatedAt);
            const dateB = new Date(b?.initiatedAt);
            return dateB - dateA;
          }) })
        this.setState({ isTreated: true });
        console.log(this.state.isTreated)



    }
    componentDidUpdate(prevProps,) {
        const { handlePopulate, populate, numba, handleNumba } = this.context

        if (numba === true) {
            (Array.isArray(populate)) &&
                Get(URLAPI.GrievanceReview.Get, this.getReviews);
            // Get(URLAPI.GrievanceReview.GetTreated, this.getApproved);

        }
    }

    componentWillUnmount() {
        this._isMounted = false;

    }

    render() {
        const { handlePopulate, populate, } = this.context

        const { loading } = this.state

        return (<>
            {
                loading ? <LoadingSpinnerEdo /> :
                    <div className="w-100">
                        <div className="flex flex-v-center space-between">
                            <div className="bold-text" style={{ ...pageTitle }}>
                                Grievance Review
                            </div>
                            <LocationDetails />
                        </div>
                        <div className="w-100 m-t-20 flex space-between flex-v-center">
                            <div />
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                <div style={{ display: "flex", }}>
                                    <div className="approvePending" onClick={this.handlePending} style={{ display: "flex", alignItems: "center" }}>
                                        {/* <p  className="badger">{this.state.holder.length}</p> */}

                                        <p>
                                            Pending Requests
                                        </p>
                                    </div>
                                    <div className="approvePending" onClick={this.handleApproved} style={{ display: "flex", alignItems: "center" }}>
                                        <div>
                                            {/* <div className="badger" > */}
                                            {/* <p className="">{this.state.completedMemo.length}</p> */}
                                            {/* </div> */}
                                            <p>
                                                Treated Requests
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <form>
                                    <SearchInput onChange={(e) => this.searchSpace(e)} />
                                </form>
                            </div>
                        </div>

                        <div className="table-view">
                            <div className="table-header">
                                <div className="table-row">
                                    <div className="th" style={{ width: "33%" }}><p className="san-sherif">Title</p></div>
                                    <div className="th" style={{ width: "25%" }}><p className="san-sherif">From</p></div>
                                    <div className="th" style={{ width: "23%" }} s><p className="san-sherif ">Status</p></div>
                                    <div className="th m-l-10" ><p className="san-sherif " >MemoDate</p></div>
                                </div>
                            </div>
                        </div>

                        <div className="table-body">

                            {
                                this.state.reviews.length === 0 ? <h3> No pending memo(s)</h3> : (
                                    <div >
                                        {
                                            console.log(this.state.reviews)

                                        }
                                        {
                                            this.state.reviews?.sort((a, b) => {
                                                const dateA = new Date(a?.memoDate);
                                                const dateB = new Date(b?.memoDate);
                                                return dateB - dateA;
                                              })?.filter((data) => {
                                                if (this.state.search == null)
                                                    return data
                                                else if (data?.subject?.toLowerCase()?.includes(this.state.search.toLowerCase()) || data?.initiator?.toLowerCase()?.includes(this.state.search.toLowerCase()) || data?.title?.toLowerCase()?.includes(this.state.search.toLowerCase()) || data?.from?.toLowerCase()?.includes(this.state.search.toLowerCase())) {
                                                    return data
                                                }
                                            }).map(
                                                ({ id, status, subject, isTreated, memoDate, title, from, initiator, through, to, role, content, docPath, comments, documents, initiatedAt }) => (
                                                    <TransactionCard
                                                        key={id}
                                                        subject={subject}
                                                        title={title}
                                                        to={to}
                                                        id={id}
                                                        from={from}
                                                        initiator={initiator}
                                                        through={through}
                                                        status={status}
                                                        content={content}
                                                        memoDate={memoDate}
                                                        documents={documents}
                                                        comments={comments}
                                                        isTreated={this.state.isTreated}
                                                        role={role}
                                                        initiatedAt={initiatedAt}
                                                    />
                                                )
                                            )}
                                    </div>
                                )}
                        </div>

                        {/* <div>

                        {
                        this.state.reviewsA.length === 0 ? <h3> No pending memo(s)</h3> : (
                                <div >
                            {
                            console.log(this.state.reviews)
                            
                            }
                            {
                            this.state.reviews.filter((data) => {
                            if (this.state.search == null)
                            return data
                            else if (data.name.toLowerCase().includes(this.state.search.toLowerCase()) || data.subject.toLowerCase().includes(this.state.search.toLowerCase())) {
                            return data
                            }
                            }).map(
                            ({ id, status, subject, memoDate, from, through, to, content, docPath, comment,documents }) => (
                            <TransactionCard
                            key={id}
                            subject={subject}
                            to={to}
                            id={id}
                            from={from}
                            through={through}
                            status={status}
                            content={content}
                            memoDate={memoDate}
                            documents={documents}
                            comment={comment}
                            // func ={this.collectort}
                            />
                            )
                            )}
                            </div>
                        )}
                            </div> */}


                    </div>

            }

        </>);
    }
}

export default GrievanceReviews;