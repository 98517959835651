import React, { Component } from 'react'
import { RiAddLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import LocationDetails from '../layouts/LocationDetails';
import GrievanceTypeForm from '../layouts/GrievanceTypeForm';
import { URLAPI } from '../../utility/ApiMethods'
import { Delete, Get } from '../../utility/fetch'
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';



const pageTitle = {
    fontSize: "1.3rem",
};

class GrievanceType extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            grievances: "",
            grievanceTypeID: 0,
            descriptions: "",
            visible: false,
            errorMessage: "",
            className: '',
            class: "add-button pointer",
            class2: "pointer",
            button: "Add-Type",
            loading: true,
            visible: false,
            grievance: [],
            status: 0,
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openModalOnEdit = this.openModalOnEdit.bind(this);
        this.getGrievances = this.getGrievances.bind(this);

    }




    openModal = () => {
        //check if button in state is add-job level so as to open modal form
        if (this.state.button == 'Add-Type')
            this.setState({
                visible: true
            })
        console.log('clicked')

    }


    closeModal = () => {
        this.setState({
            visible: false, loading: true

        }, () => {
            Get(URLAPI.ReportGrievance.Get, this.getGrievances);
        });

    }


    //open modal with details to change and change button label 
    openModalOnEdit = (item) => {
        //set record to state
        console.log(item);
        if (item !== null) {
            this.setState({
                button: 'Update',
                grievanceTypeID: item.id,
                grievances: item.grievanceType,
                descriptions: item.description,
                visible: true,
            })
        }
    }

    getGrievances = (data) => {
        console.log(data);

        if (this._isMounted) {
            let _grievance = Array.isArray(data.data) ? data.data : [];
            this.setState({ grievance: _grievance, loading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        Get(URLAPI.ReportGrievance.Get, this.getGrievances);
    }

    // componentDidUpdate() {
    //     this._isMounted = true;
    //         Get(URLAPI.ReportGrievance.Get, this.getGrievances);


    // }


    componentWillUnmount() {
        this._isMounted = false;
    }

    //handle delete record
    handleDeleteRecord(record) {

        deleteMessage(
            () => this.onDelete(record)
        )
    }





    onDelete = (record) => {
        console.log("clicked");
        if (record !== "") {
            this.setState({ loading: true }, () => {
                Delete(URLAPI.ReportGrievance.Delete + "/" + record.id, this.onAfterDelete);

            });
        }
    }

    onAfterDelete = (res) => {
        if (res.status === "success") {
            // console.log(res);
            this.setState({ loading: false })
            successMessage(res.message)

            Get(URLAPI.ReportGrievance.Get, this.getGrievances);

            this.setState({ grievance: this.state.grievance.filter((rec) => rec.id !== res.id) });
            // this.handleCancel();
        }
        else if (res.status === "failed") {

            errorMessage(res.message)
            this.setState({ loading: false, visible: false });
        }
        else {

            errorMessage(res.message);
        }

    }



    render() {
        const { loading } = this.state
        return (
            <>

                {loading ? <LoadingSpinnerEdo /> :
                    <div className="w-100">
                        <div className="flex flex-v-center space-between">
                            <div className="bold-text" style={{ ...pageTitle }}>
                                Grievance Type
                 </div>
                            <LocationDetails />
                        </div>
                        <div className="table-view m-t-10">
                            <div className="table-header" >
                                <div className="table-row">
                                    <div className="th" ><p className="san-sherif">GrievanceType</p></div>
                                    <div className="th"><p className="san-sherif">Description</p></div>
                                    <div className="th"><p className="san-sherif">ModifiedBy</p></div>
                                    <div className="th"><p className="san-sherif">ModifiedAt</p></div>

                                    <div className="th">
                                        <button className={this.state.class} type="button" onClick={this.openModal}>
                                            <RiAddLine />
                                            {this.state.button}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-body">
                                {this.state.grievance.map((item) =>
                                    <div className="table-row" key={item.id}>
                                        <div className="td">{item.grievanceType}</div>
                                        <div className="td">{item.description}</div>
                                        <div className="td">{item.modifiedBy||item.createdBy}</div>
                                        <div className="td">{(item.modifiedAt==='1/1/0001 12:00 AM')?item.createdAt:item.modifiedAt}</div>
                                        <div className="td actions">
                                            <button type="button" className={this.state.class2} onClick={() => this.openModalOnEdit(item)}>
                                                <div className="tooltip">
                                                    <BiEditAlt />
                                                    <span className="tooltiptext">Edit</span>
                                                </div>
                                            </button>
                                            <button type="button" className="m-l-10 del pointer" onClick={() => this.handleDeleteRecord(item)}>
                                                <div className="tooltip">
                                                    <GiTrashCan />
                                                    <span className="tooltiptext">Delete</span>
                                                </div>

                                            </button>
                                        </div>
                                    </div>
                                )}

                            </div>
                            {/**check if visible is set to true to open modal and set closemodal state props usable in child component */}
                            {this.state.visible ? <GrievanceTypeForm button={this.state.button} description={this.state.descriptions} typeid={this.state.grievanceTypeID} edittype={this.state.grievances} getGrievances={this.getGrievances} closeModal={() => this.setState({ visible: false })} grievance={this.state.grievance} /> : null}

                        </div>


                    </div>
                }

            </>);

    }
}

export default GrievanceType;