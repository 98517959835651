export const URLAPI = {
    ReportGrievance: {
        Post: "/api/Grievance/CreateGrievance",
        Get: "/api/Grievances",
        Put: "/api/Grievance/UpdateGrievance",
        Delete: "/api/Grievance/DeleteGrievance",

    },

    ReportApproval: {
        // Post: "​/api/Grievance/CreateApprovalRoute",
        Get: "/api/Grievance/ApprovalRoutes",
        Put: "/api/Grievance/UpdateApprovalRoute",
        Delete: "/api/Grievance/DeleteApprovalRoute",
    },

    ApprovalRoute: "/api/Grievance/CreateApprovalRoute",

    Auth: {

        Post: "/api/Authentication/AccessToken",
        PostId: "/api/Auth/signin",
    },
    GrievanceReview: {
        Get: "/api/Grievance/Memo/PendingMemos",
        GetTreated: "/api/Grievance/Memo/CompletedMemos"
    },

    MemoOnTransist: "/api/Grievance/Memo/MemoOnTransist",
    CompletedMemos: "/api/Grievance/Memo/CompletedMemos",
    MemoStatistics: "/api/Grievance/Memo/MemoStatistics",
    MemoPost: "/api/Grievance/Memo/CreateMemo",
    MemoSubmitRequest: "/api/Grievance/Memo/submit",
    MemoApproval: "/api/Grievance/Memo/ApproveMemo",
    MemoGet: "/api/Grievance/Memos",
    MemoFetchUser: "/api/Grievance/Memo/User",
    Map: "/services/api/userlocations",
    MemoPut: "/api/Grievance/Memo/UpdateMemo",
    MemoDelete: "/api/Grievance/Memo/DeleteMemo",
    MemoTo: "/api/Grievance/Memo/To",
    MemoUser: "/api/Grievance/Memo/User",
    ApprovalRoles: "/api/Grievance/ApprovalRoute/Roles",
    searchDocument:"/services/api/dashboard/searchdocument"



};


